import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="splash" style={{ height: `100vh`, padding: `200px`}}>
      <div style={{ maxWidth: `500px`, margin: `auto auto` }}>
        <Image />
      </div>
    </div>
  </Layout>
)

export default IndexPage
